import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CommonDialog from "app/components/common/CommonDialog";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { boards, Distrct, States } from "app/config/administrativeConstants";
import { updateInstituteGroup } from "app/services/org/school.service";
import { MenuProps } from "app/utils/helper";
import uploadFile from "app/utils/uploadFile";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as Yup from "yup";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const validationSchema = Yup.object({
  instituteGroupName: Yup.string().required("School name required"),
  address: Yup.string().required("School address required"),
  dist: Yup.string().required("district required"),
  state: Yup.string().required("state required"),
  pinCode: Yup.string().required("Pin Code required"),
  email: Yup.string().matches(emailRegex, "Invalid email"),
  mobileNumber: Yup.string().min(10, "10 digit required"),
  website: Yup.string().url("Invalid website"),
  schoolContactNumber: Yup.string().min(10, "10 digit required"),
});

const UpdateInsituteProfile = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  state,
  setState,
  instituteGroupDocId,
}) => {
  const [loading, setLoading] = useState(false);
  const { mutate, isLoading: updateInstituteGroupLoading } = useMutation(
    "updateInstituteGroup",
    updateInstituteGroup,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        onClose();
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      instituteGroupLogoFile: "",
      coverPhotoFile: "",
      instituteGroupLogo: "",
      coverPhoto: state?.coverPhoto ?? "",
      schoolContactNumber: state?.contactNumber ?? "",
      instituteGroupName: state?.instituteGroupName ?? "",
      address: state?.address?.address ?? "",
      dist: state?.address?.dist ?? "",
      pinCode: state?.address?.pinCode ?? "",
      state: state?.address?.state ?? "",
      schoolEmail: state?.email ?? "",
      schoolWebsite: state?.website ?? "",
    },
    onSubmit: (values) => {
      const updateProfileFirstPayload = {
        instituteGroupDocId: instituteGroupDocId,

        instituteGroupLogo: values.instituteGroupLogo,

        instituteGroupName: values.instituteGroupName,
        coverPhoto: values.coverPhoto,
        address: {
          address: values.address,
          dist: values.dist,
          pinCode: values.pinCode,
          state: values.state,
        },
        contactNumber: String(values.schoolContactNumber),
        website: values?.schoolWebsite,
        // email: values.schoolEmail,
      };

      handleSubmit(updateProfileFirstPayload);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    let coverPhoto = "";
    let instituteGroupLogo = "";
    if (formik.values.coverPhotoFile) {
      const s3Location = `instituteGroup/${formik.values?.coverPhotoFile?.name}`;
      const { fileURL } = await uploadFile(
        formik.values?.coverPhotoFile,
        s3Location,
        formik.values.coverPhotoFile?.type
      );
      coverPhoto = fileURL;
    }
    if (formik.values.instituteGroupLogoFile) {
      const s3Location = `instituteGroup/${formik.values?.instituteGroupLogoFile?.name}`;
      const { fileURL } = await uploadFile(
        formik.values?.instituteGroupLogoFile,
        s3Location,
        formik.values.instituteGroupLogoFile?.type
      );
      instituteGroupLogo = fileURL;
    }
    setLoading(false);
    mutate({ ...values, coverPhoto, instituteGroupLogo });
  };
  return (
    <>
      <Dialog open={loading}>
        <Stack
          m={2}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size={35} />
          <Typography>Uploding File....</Typography>
        </Stack>
      </Dialog>
      <CommonDialog
        open={open}
        onClose={onClose}
        minWidth="600px"
        title="Update Profile"
      >
        <DialogContent>
          <UploadedCoverPhoto
            src={
              formik.values.coverPhoto
                ? formik.values.coverPhoto
                : "/banner-placeholder.png"
            }
          />
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            sx={{ borderRadius: "10px" }}
            component="label"
          >
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={(e) =>
                formik.setValues((prev) => ({
                  ...prev,
                  coverPhoto: URL.createObjectURL(e.target.files[0]),
                  coverPhotoFile: e.target.files[0],
                }))
              }
            />
            Upload Cover Photo
          </Button>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <ProfilePic
              src={
                formik.values.instituteGroupLogo
                  ? formik.values.instituteGroupLogo
                  : "/image-placeholder.jpeg"
              }
            />
            <Button
              component="label"
              variant="outlined"
              size="large"
              color="secondary"
              sx={{ borderRadius: "10px" }}
            >
              <input
                hidden
                type="file"
                accept="image/*"
                onChange={(e) =>
                  formik.setValues((prev) => ({
                    ...prev,
                    instituteGroupLogo: URL.createObjectURL(e.target.files[0]),
                    instituteGroupLogoFile: e.target.files[0],
                  }))
                }
              />
              Upload Logo
            </Button>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextFieldLabel title="Group Of Insitute's Name" required />
              <CustomTextField
                id="groupOfInstituteName"
                name="instituteGroupName"
                size="small"
                placeholder="type school name"
                fullWidth
                value={formik.values.instituteGroupName}
                onChange={formik.handleChange}
                error={
                  formik.touched.instituteGroupName &&
                  Boolean(formik.errors.instituteGroupName)
                }
                helperText={
                  formik.touched.instituteGroupName &&
                  formik.errors.instituteGroupName
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldLabel title="School Address" />
              <CustomTextField
                size="small"
                fullWidth
                id="address"
                name="address"
                placeholder="type school address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title="State" />
              <CustomSelectBox
                displayEmpty
                fullWidth
                size="small"
                id="state"
                value={formik.values.state}
                name="state"
                onChange={formik.handleChange}
                MenuProps={MenuProps}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {States.map((state, index) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title="District" />
              <CustomSelectBox
                size="small"
                fullWidth
                displayEmpty
                id="dist"
                value={formik.values.dist}
                name="dist"
                onChange={formik.handleChange}
                MenuProps={MenuProps}
                error={formik.touched.dist && Boolean(formik.errors.dist)}
                helperText={formik.touched.dist && formik.errors.dist}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {formik.values.state &&
                  Distrct[States.indexOf(String(formik.values.state)) + 1].map(
                    (district) => (
                      <MenuItem key={district} value={district}>
                        {district}
                      </MenuItem>
                    )
                  )}
              </CustomSelectBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title="Pincode" />
              <CustomTextField
                fullWidth
                placeholder="type pin code"
                size="small"
                id="pinCode"
                name="pinCode"
                value={formik.values.pinCode}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 6,
                }}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
              />
            </Grid>

            {/* <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Email" />
            <CustomTextField
              size="small"
              fullWidth
              placeholder="type school email"
              name="schoolEmail"
              value={formik.values.schoolEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.schoolEmail && Boolean(formik.errors.schoolEmail)
              }
              helperText={
                formik.touched.schoolEmail && formik.errors.schoolEmail
              }
            />
          </Grid> */}
            <Grid item xs={12} md={6}>
              <TextFieldLabel title="School Website" />
              <CustomTextField
                placeholder="type school website"
                size="small"
                fullWidth
                id="schoolWebsite"
                name="schoolWebsite"
                value={formik.values.schoolWebsite}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldLabel title="School Contact Number" />
              <CustomTextField
                size="small"
                placeholder="type school contact"
                fullWidth
                id="schoolContactNumber"
                name="schoolContactNumber"
                // type="number"
                value={formik.values.schoolContactNumber}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  if (String(e.target.value).length < 11) {
                    formik.handleChange(e);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldLabel title="School Mobile Number" />
              <CustomTextField
                placeholder="type school mobile Number"
                size="small"
                fullWidth
                id="mobileNumber"
                name="mobileNumber"
                type="number"
                error={
                  formik.touched.mobileNumber &&
                  Boolean(formik.errors.mobileNumber)
                }
                helperText={
                  formik.touched.mobileNumber && formik.errors.mobileNumber
                }
                value={formik.values.mobileNumber}
                onChange={(e) => {
                  if (String(e.target.value).length < 11) {
                    formik.handleChange(e);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={updateInstituteGroupLoading}
            variant="contained"
            color="secondary"
            onClick={formik.handleSubmit}
            sx={{ textTransform: "capitalize" }}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default UpdateInsituteProfile;

const UploadedCoverPhoto = styled("img")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 142px;
  border-radius: 20px;
  margin-bottom: 10px;
`;

const ProfilePic = styled("img")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  border-radius: 20px;
  margin: 10px;
`;
