import { axiosInstance } from "../axios";
const collection_route = `${process.env.REACT_APP_ORG_BASE}/collection`;

export const changeDueDateOfSchool = (body) => {
  return axiosInstance.put(`${collection_route}/changeDueDateOfSchool`, body);
};

export const getSchoolListWithAmount = (params) => {
  return axiosInstance.get(`${collection_route}/getSchoolListWithAmount`, {
    params,
  });
};
export const collectPayment = (body) => {
  return axiosInstance.put(`${collection_route}/collectPayment`, body);
};
export const changeInvoiceDate = (body) => {
  return axiosInstance.put(`${collection_route}/changeInvoiceDate`, body);
};
export const getInvoice = (params) => {
  return axiosInstance.get(`${collection_route}/getInvoice`, {
    params,
  });
};
export const deleteInvoice = (invoiceDocId) => {
  return axiosInstance.delete(
    `${collection_route}/deleteInvoice/${invoiceDocId}`
  );
};
