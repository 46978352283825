import Fallback from "app/components/Fallback";
import React from "react";

const SchoolErp = React.lazy(() =>
  import("app/modules/accessControl/pages/schoolErp/SchoolErp")
);

const OrgErp = React.lazy(() =>
  import("app/modules/accessControl/pages/orgErp/OrgErp")
);

export const SchoolErpPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolErp {...props} />
  </React.Suspense>
);
export const OrgErpPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <OrgErp {...props} />
  </React.Suspense>
);
