import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  approveSMStemplate,
  rejectSMStemplate,
} from "app/services/sms/orgdlt.service";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const ApproveTemplateDialog = ({
  open = false,
  onClose = () => {},
  handleRefresh = () => {},
  selectedTemplate = {},
}) => {
  console.log("ApproveTemplateDialog -> selectedTemplate", selectedTemplate);
  const [state, setState] = useState({
    messageNumber: selectedTemplate?.message ?? "",
    variableCount: selectedTemplate?.variablesCount ?? "",
    messageCount: selectedTemplate?.messageCount ?? "",
    variables: selectedTemplate?.variables ?? {},
    username: "",
  });

  const {
    mutate: approveSMStemplateMutate,
    isLoading: approvedSmsTemplateLoading,
  } = useMutation(approveSMStemplate, {
    onSuccess: (success) => {
      handleRefresh();
      setState((prev) => ({
        ...prev,
        messageNumber: "",
        variableCount: "",
        messageCount: "",
        variables: {},
        username: "",
      }));
      onClose();
      toast.success(success?.data?.message ?? "Template Approved Successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "Error");
    },
  });
  const {
    mutate: rejectSMStemplateMutate,
    isLoading: rejectSMSTempalteLoading,
  } = useMutation(rejectSMStemplate, {
    onSuccess: (success) => {
      handleRefresh();
      onClose();
      toast.success(success?.data?.message ?? "Template Rejected Successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "Error");
    },
  });

  useEffect(() => {
    if (open && selectedTemplate) {
      setState((prev) => ({
        ...prev,
        messageNumber: selectedTemplate?.message,
        variableCount: selectedTemplate?.variablesCount,
        messageCount: selectedTemplate?.messageCount,
        variables: selectedTemplate?.variables ?? {},
        username: "",
      }));
    }
  }, [open, selectedTemplate]);

  const handleUsernameChange = (key) => {
    setState((prev) => ({
      ...prev,
      username: key,
    }));
  };

  const handleApprove = () => {
    approveSMStemplateMutate({
      smsTemplateDocId: selectedTemplate?._id,
      message: state?.messageNumber,
      variablesCount: state?.variableCount,
      messageCount: state?.messageCount,
      variables: state?.variables ?? {},
      //   username: state?.username,
    });
  };

  const handleReject = () => {
    rejectSMStemplateMutate({
      tempalteDocId: selectedTemplate?._id,
    });
  };

  return (
    <CommonDialog
      open={open}
      onClose={() => {
        setState((prev) => ({
          ...prev,
          messageNumber: "",
          variableCount: "",
          messageCount: "",
          variables: {},
          username: "",
        }));
        onClose();
      }}
      title="Approve Template"
      minWidth="800px"
    >
      <DialogContent>
        <Grid container spacing={2}>
          {/* message number */}
          <Grid item xs={4}>
            <TextFieldLabel title={"Message Number"} />
            <CustomTextField
              fullWidth
              value={state.messageNumber}
              onChange={(e) =>
                setState((prev) => ({ ...prev, messageNumber: e.target.value }))
              }
              placeholder="Enter Message Number"
            />
          </Grid>
          {/* variable count */}
          <Grid item xs={4}>
            <TextFieldLabel title={"Variable Count"} />
            <CustomTextField
              fullWidth
              value={state.variableCount}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  variableCount: e.target.value,
                  variables: Array.from({ length: e.target.value }, (_, i) => ({
                    [`variable${i + 1}`]: "",
                  })).reduce((acc, curr) => {
                    return { ...acc, ...curr };
                  }, {}),
                }))
              }
              placeholder="Enter Variable Count"
            />
          </Grid>
          {/* message count */}
          <Grid item xs={4}>
            <TextFieldLabel title={"Message Count"} />
            <CustomTextField
              fullWidth
              value={state?.messageCount}
              onChange={(e) =>
                setState((prev) => ({ ...prev, messageCount: e.target.value }))
              }
              placeholder="Enter Message Count"
            />
          </Grid>

          {/* variables */}
          <Grid item xs={8}>
            <TextFieldLabel title={"Variables"} />
            <Stack direction={"column"} spacing={1}>
              {Object.keys(state?.variables).map((key, index) => (
                <Typography
                  key={index}
                  variant="subtitle1"
                  style={{ marginBottom: "10px" }}
                >
                  {key} : {state.variables[key]}
                </Typography>
              ))}
            </Stack>
          </Grid>
          {/* username */}
          <Grid item xs={4}>
            <TextFieldLabel title={"Username"} />
            <Stack direction={"column"} spacing={1}>
              {Object.keys(state.variables).map((key, index) => (
                <Switch
                  key={index}
                  checked={state?.username === key}
                  onChange={() => handleUsernameChange(key)}
                  name="username"
                  inputProps={{ "aria-label": "username" }}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          size="large"
          color="error"
          variant="contained"
          onClick={handleReject}
          loading={rejectSMSTempalteLoading}
          disabled={rejectSMSTempalteLoading}
        >
          Reject
        </LoadingButton>
        <LoadingButton
          size="large"
          color="success"
          variant="contained"
          onClick={handleApprove}
          loading={approvedSmsTemplateLoading}
          disabled={approvedSmsTemplateLoading}
        >
          Approve
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default ApproveTemplateDialog;
