import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import FilterButton from "app/components/common/FilterButton";
import TableActionButtons from "app/components/common/TableActionButtons";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import CommonFilterDialog from "app/components/Dialogs/CommonFilterDialog";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { getSmsTemplatesByOrg } from "app/services/sms/orgdlt.service";
import ApproveTemplateDialog from "app/components/Dialogs/Communication/ApproveTemplateDialog";

const validationSchema = yup.object({
  senderId: yup.string().required("Sender id required"),
  templateId: yup.string().required("Template id required"),
  templateType: yup.string().required("Template type required"),
  templateName: yup.string().required("Template Name required"),
  messageText: yup.string().required("Message required"),
  attachments: yup.string().required("Image required"),
});

const SmsTemplateTab = () => {
  const [state, setState] = useState({
    filterDialog: false,
    from: new Date(),
    to: new Date(),
    status: "ACTIVE",
  });

  const {
    values,
    setValues,
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      senderId: "",
      templateId: "",
      templateType: "Service Implicit",
      templateName: "",
      messageText: "",
      attachments: "",
      edit: false,
      editId: "",
    },
    validationSchema,
    onSubmit: (formValue) => {
      let query = {
        senderId: formValue.senderId,
        contentTemplateID: formValue.templateId,
        templateType: formValue.templateType,
        templateName: formValue.templateName,
        smsTemplate: formValue.messageText,
        approvedSenderIdScreenshot: formValue.attachments,
      };
      if (formValue.edit) {
        query["smsTemplateDocId"] = values?.editId;
        // editSMStemplateMutate(query);
      } else {
        // handleAddEntity(query);
      }
    },
  });

  const [checkboxes, setCheckboxes] = useState({
    status: false,
    byDate: false,
  });

  const columns = useMemo(
    () => [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography
            style={{
              borderRadius: "8px",
              border: "none",
              outline: "none",
              textOverflow: "ellipsis",
            }}
          >
            {parseInt(cell.row.id) + 1 < 10
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </Typography>
        ),
        maxSize: 20,
      },

      {
        header: "Applied On",
        accessorKey: "createdOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {dayjs(original?.createdOn).format("DD MMM, YYYY")}
          </Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Sender ID",
        accessorKey: "senderId",
        maxSize: 20,
      },
      {
        header: "Content Template ID",
        accessorKey: "contentTemplateID",
        maxSize: 20,
      },
      {
        header: "Template Type",
        accessorKey: "templateType",

        maxSize: 20,
      },
      {
        header: "Approved Message",
        accessorKey: "smsTemplate",
        maxSize: 20,
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography
            sx={{
              color:
                original?.status === "APPROVED"
                  ? "rgba(91, 206, 0, 1)"
                  : original?.status === "REQUESTED"
                  ? "rgba(236, 168, 32, 1)"
                  : original?.status === "REJECTED" && "rgba(244, 59, 59, 1)",
            }}
          >
            {original?.status}
          </Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Action Taken On",
        accessorKey: "updatedOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {dayjs(original?.updatedOn).format("DD MMM, YYYY")}
          </Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Actions  ",
        Cell: ({ cell }) => {
          return (
            <TableActionButtons
              showEdit={false}
              showView={
                cell?.row?.original?.status === "REQUESTED" ? true : false
              }
              handleEdit={() => handleShowEdit(cell)}
              handleView={() => handleView(cell)}
            />
          );
        },
        size: 10,
      },
    ],
    []
  );

  const handleView = (cell) => {
    const {
      row: { original },
    } = cell;
    setState((prev) => ({ ...prev, viewData: original, viewModal: true }));
  };

  const handleShowEdit = (cell) => {
    const {
      row: { original },
    } = cell;
    setValues((prev) => ({
      ...prev,
      editId: original?._id,
      senderId: original?.senderId,
      templateId: original?.contentTemplateID,
      templateType: original?.templateType,
      templateName: original?.templateName,
      messageText: original?.smsTemplate,
      attachments: original?.approvedSenderIdScreenshot,
      edit: true,
    }));
  };

  const {
    isLoading: getSmsTemplatesByOrgLoading,
    isFetching: getSmsTemplatesByOrgFetching,
    refetch: getSmsTemplatesByOrgRefetch,
    data: getSmsTemplatesByOrgData,
  } = useQuery({
    queryKey: ["getSmsTemplatesByOrg"],
    queryFn: () =>
      getSmsTemplatesByOrg({
        ...(checkboxes?.status && { status: state.status }),
        ...(checkboxes?.byDate && {
          from: dayjs(state.from).format("YYYY-MM-DD"),
          to: dayjs(state.to).format("YYYY-MM-DD"),
        }),
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CommonFilterDialog
        handleSaveAndView={() => {
          setState((prev) => ({ ...prev, filterDialog: false }));
          getSmsTemplatesByOrgRefetch();
        }}
        open={state.filterDialog}
        onClose={() => setState((prev) => ({ ...prev, filterDialog: false }))}
        title="Filter"
        minWidth="600px"
      >
        <DialogContent>
          {/* by date */}
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.byDate}
                  onChange={(e) => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      byDate: !prev.byDate,
                    }));
                  }}
                />
              }
              label="By Date"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            {checkboxes.byDate && (
              <Stack direction="row" gap={2}>
                <Stack flex={1}>
                  <TextFieldLabel title="From" />
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    name="from"
                    value={state.from}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        from: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>

                <Stack flex={1}>
                  <TextFieldLabel title="To" />
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    name="to"
                    value={state.to}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        to: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>

          {/* status */}
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.status}
                  onChange={(e) => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      status: !prev.status,
                    }));
                  }}
                />
              }
              label="Status"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            {checkboxes.status && (
              <Box>
                {checkboxes.status && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <CustomSelectBox
                        fullWidth
                        displayEmpty
                        value={state.status}
                        size="small"
                        MenuProps={MenuProps}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }))
                        }
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                        <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                        <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                      </CustomSelectBox>
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>

      <ApproveTemplateDialog
        open={state.viewModal}
        onClose={() => setState((prev) => ({ ...prev, viewModal: false }))}
        handleRefresh={getSmsTemplatesByOrgRefetch}
        selectedTemplate={state.viewData}
      />

      <Box sx={{ mt: 2 }}>
        <FilterButton
          count={
            Object.values(checkboxes).filter((item) => item === true).length
          }
          handleClick={() =>
            setState((prev) => ({ ...prev, filterDialog: true }))
          }
        />
        <Box
          sx={{
            my: 2,
          }}
        >
          <CommonTable1
            columns={columns}
            data={getSmsTemplatesByOrgData?.data?.list ?? []}
            maxHeight="70vh"
          />
        </Box>
      </Box>
    </>
  );
};

export default SmsTemplateTab;
