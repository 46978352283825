import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import FilterDialogHeader from "app/components/common/FilterDialogHeader";
import { FilterAlt, Settings, SettingsOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import {
  changeProductStatus,
  deleteProduct,
  getProduct,
} from "app/services/org/product.service";
import { toast } from "react-toastify";
import CommonTable1 from "app/components/Tables/CommonTable1";
import AddProductDialog from "app/components/Dialogs/Product/AddProductDialog";
import ProductFilter from "app/components/Dialogs/Product/ProductFilter";
import { IndianCurrency } from "app/utils/helper";
import CommonDialog from "app/components/common/CommonDialog";
import { LoadingButton } from "@mui/lab";
import ViewProductDialog from "app/components/Dialogs/Product/ViewProductDialog";
import useSelectorHook from "app/services/redux/useSelectorHook";
import CommonTablePagination from "app/components/Tables/CommonTablePagination";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
const Product = () => {
  const [state, setState] = useState({
    list: [],
    addNewDialog: false,
    filterData: false,
    productView: "",
    deleteProduct: {
      dialog: false,
      data: {},
    },
    settingProduct: {
      dialog: false,
      data: {},
    },
    page: 1,
    pages: 1,
  });

  const [openCheckBox, setOpenCheckBox] = useState(false);
  const { selectedSession } = useSelectorHook();
  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteProduct: { dialog: false, data: {} },
    }));
  };
  const handleCloseSettingDialog = () => {
    setState((prev) => ({
      ...prev,
      settingProduct: { dialog: false, data: {} },
    }));
  };
  const handleCallGetroduct = (payload) => {
    setState((prev) => ({ ...prev, filterData: payload }));
  };
  const {
    isLoading: changeProductStatusLoading,
    mutate: changeProductStatusMutate,
  } = useMutation("changeProductStatus", changeProductStatus, {
    onSuccess: (success) => {
      getProductRefetch();
      toast.success(success?.data?.message);
      handleCloseSettingDialog();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { isLoading: deleteProductLoading, mutate: deleteProductMutate } =
    useMutation("deleteProduct", deleteProduct, {
      onSuccess: (success) => {
        getProductRefetch();
        toast.success(success?.data?.message);
        handleCloseDeleteDialog();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const {
    isLoading: getProductLoading,
    isFetching: getProductFetching,
    refetch: getProductRefetch,
  } = useQuery({
    queryKey: ["getProduct", state.filterData, selectedSession, state.page],
    queryFn: () => {
      if (state.filterData) {
        return getProduct({
          ...state.filterData,
          session: selectedSession,
          page: state.page,
        });
      } else {
        return getProduct({ session: selectedSession, page: state.page });
      }
    },
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        list: success?.data?.list,
        pages: success?.data?.pages,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Product Type",
        accessorKey: "productType",
        maxSize: 40,
      },
      {
        header: "Product Name - Description",
        accessorKey: "productName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.productName}-{original?.productDescription}
          </Typography>
        ),
        maxSize: 100,
      },

      {
        header: "UOM",
        accessorKey: "unitOfMesurement",
        maxSize: 30,
      },
      {
        header: "Rate",
        accessorKey: "rate",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{IndianCurrency(original?.rate)}</Typography>,

        maxSize: 30,
      },
      {
        header: "Factor",
        accessorKey: "factor",
        maxSize: 30,
      },
      {
        header: "Final Rate",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {IndianCurrency(
              Number(original?.rate ?? 0) * Number(original?.factor ?? 0)
            )}
          </Typography>
        ),
        maxSize: 30,
      },

      {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell, row }) => {
          return (
            <Stack direction="row" gap={0.5}>
              <Typography
                sx={{
                  color:
                    row?.original?.status === "APPROVED"
                      ? "rgba(20, 219, 28, 1)"
                      : row?.original?.status === "REJECTED"
                      ? "rgba(255, 0, 0, 1)"
                      : "rgba(251, 177, 34, 1)",
                }}
              >
                {row?.original?.status}
              </Typography>
            </Stack>
          );
        },
        maxSize: 20,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Stack direction={"row"} gap={0.8} alignItems={"center"}>
              <Box
                onClick={() =>
                  setState((prev) => ({ ...prev, productView: original?._id }))
                }
                sx={{ cursor: "pointer" }}
              >
                <img
                  alt="view"
                  src="/view-icon.png"
                  style={{
                    height: "28px",
                    width: "28px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              {["REQUESTED"].includes(original?.status) && (
                <Box
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      settingProduct: {
                        dialog: true,
                        data: original,
                      },
                    }))
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <SettingsOutlined
                    sx={{ fontSize: "23px", color: "#2789FD" }}
                  />
                </Box>
              )}
              <Box
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    deleteProduct: {
                      dialog: true,
                      data: original,
                    },
                  }))
                }
                sx={{ cursor: "pointer" }}
              >
                <img
                  alt="delete"
                  src="/delete-icon.png"
                  style={{
                    height: "28px",
                    width: "28px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Stack>
          );
        },
        maxSize: 40,
      },
    ];
  }, []);

  let header = [
    "Product Type",
    "Product Name - Description",
    "UOM",
    "Rate",
    "Factor",
    "Final Rate",
    "Status",
  ];
  let exportArray = state.list?.map((item) => {
    return {
      "Product Type": item?.productType,
      "Product Name - Description": `${item?.productName}--${item?.productDescription}`,
      UOM: item?.unitOfMesurement,
      Rate: item?.rate,
      Factor: item?.factor,
      "Final Rate": `${IndianCurrency(
        Number(item?.rate ?? 0) * Number(item?.factor ?? 0)
      )}`,
      Status: item?.status,
    };
  });

  return (
    <>
      <CustomLoader show={getProductLoading || getProductFetching} />

      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Product List"
      />
      {/* view Dialog */}
      <ViewProductDialog
        productId={state.productView}
        onClose={() => setState((prev) => ({ ...prev, productView: "" }))}
      />
      {/* Delete product Dialog */}
      <CommonDialog
        open={state.deleteProduct.dialog}
        onClose={handleCloseDeleteDialog}
        title="Delete Product"
      >
        <DialogContent>
          <Stack direction={"row"} justifyContent={"center"}>
            <img
              alt="delete"
              src="/delete-icon.png"
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain",
              }}
            />
          </Stack>
          <Typography
            fontWeight={600}
            fontSize={"16px"}
            textAlign={"center"}
            my={2}
          >
            Are You Sure want to Delete ?
          </Typography>
          <Stack direction={"row"} justifyContent={"center"}>
            <LoadingButton
              color="error"
              variant="contained"
              sx={{ width: "80%" }}
              onClick={() =>
                deleteProductMutate(state?.deleteProduct.data?._id)
              }
              loading={deleteProductLoading}
            >
              Delete
            </LoadingButton>
          </Stack>
        </DialogContent>
      </CommonDialog>
      {/*approve / Reject product Dialog */}
      <CommonDialog
        open={state.settingProduct.dialog}
        onClose={handleCloseSettingDialog}
        title="Approve/Reject Product"
      >
        <DialogContent>
          <Typography
            fontWeight={600}
            fontSize={"16px"}
            textAlign={"center"}
            my={2}
          >
            Please Select
          </Typography>
          <Stack direction={"row"} justifyContent={"center"} gap={2}>
            <LoadingButton
              color="error"
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={() =>
                changeProductStatusMutate({
                  productDocId: state.settingProduct?.data?._id,
                  status: "REJECTED",
                })
              }
              loading={changeProductStatusLoading}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              color="success"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={() =>
                changeProductStatusMutate({
                  productDocId: state.settingProduct?.data?._id,
                  status: "APPROVED",
                })
              }
              loading={changeProductStatusLoading}
            >
              Approve
            </LoadingButton>
          </Stack>
        </DialogContent>
      </CommonDialog>
      {/* add product dialog */}
      <AddProductDialog
        open={state.addNewDialog}
        onClose={() => setState((prev) => ({ ...prev, addNewDialog: false }))}
        refetch={() => getProductRefetch()}
      />

      <Container maxWidth="xl">
        <Stack
          sx={{ pb: 1 }}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <ProductFilter mutate={handleCallGetroduct} />
          <Stack direction={"row"} gap={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() =>
                setState((prev) => ({ ...prev, addNewDialog: true }))
              }
            >
              + Add New
            </Button>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <ExportButtonGroup
                showExcel
                handleClickExcel={() => setOpenCheckBox(true)}
                showPdf={false}
              />
            </Box>
          </Stack>
        </Stack>
        <CommonTablePagination
          columns={columns}
          data={state.list}
          totalPage={state.pages}
          page={state.page}
          setPagination={(_, count) =>
            setState((prev) => ({ ...prev, page: count }))
          }
        />
      </Container>
    </>
  );
};

export default Product;
