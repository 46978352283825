import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomLoader from "app/components/common/CustomLoader";
import { toast } from "react-toastify";
import SmsTemplateTab from "./SmsTemplateTab";
import EntityNameTab from "./EntityNameTab";
import {
  approveSenderId,
  getAllSenderId,
  rejectSenderId,
} from "app/services/sms/orgdlt.service";
import { LoadingButton } from "@mui/lab";
import ChangeTemplateStatus from "app/components/Dialogs/Communication/ChangeTemplateStatus";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

const TextSMS = () => {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    open: false,
    legalEntityDocId: "",
  });

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 100,
      },
      {
        accessorKey: "senderId",
        header: "Sender Id",
        size: 150,
      },
      {
        accessorKey: "approvedEntityId",
        header: "Aprrove Entity Id",
        size: 150,
      },
      {
        accessorKey: "status", //normal accessorKey
        header: "Status",
        Cell: ({ cell }) => {
          if (cell?.row?.original?.status === "APPROVED") {
            return (
              <Typography color="green">
                {cell?.row?.original?.status}
              </Typography>
            );
          }
          if (cell?.row?.original?.status === "REJECTED") {
            return (
              <Typography color="error">
                {cell?.row?.original?.status}
              </Typography>
            );
          }
          return (
            <Typography color="orange">
              {cell?.row?.original?.status}
            </Typography>
          );
        },
        size: 150,
      },
      {
        accessorKey: "createdBy.personName",
        header: "Send By",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Send On",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ cell }) => {
          if (
            cell?.row?.original?.status === "APPROVED" ||
            cell?.row?.original?.status === "REJECTED"
          ) {
            return null;
          } else {
            return (
              <Box>
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        open: true,
                        legalEntityDocId: cell?.row?.original?._id,
                      }));
                    }}
                  >
                    Approve
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        open: true,
                        legalEntityDocId: cell?.row?.original?._id,
                      }));
                    }}
                  >
                    Reject
                  </LoadingButton>
                </Stack>
              </Box>
            );
          }
        },
      },
    ],
    []
  );

  const [view, setView] = useState(1);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const schoolDocID = "";

  const initialValues = {
    startDate: new Date("2022-01-01"),
    endDate: new Date("2023-12-31"),
    schoolDocID: schoolDocID,
  };

  const { mutate: getSenderIdDAta, isLoading: getAllSenderIdLoading } =
    useMutation(getAllSenderId, {
      onSuccess: (data) => {
        setData(data?.data);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      },
    });

  // Adding Event Form dfg
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues,
    onSubmit: (formData, action) => {
      let body = {
        from: formData.startDate,
        to: formData.endDate,
      };
      getSenderIdDAta(body);

      action.resetForm();
    },
  });

  const pages = [];
  for (let i = 0; i < data.pages; i++) {
    pages.push(i + 1);
  }

  const PageChanger = (types, page) => {
    let pageNumber = data.page;
    if (types === "page") {
      console.log(types, page);
      //pageNumber = page;
      pageNumber = page;
    } else if (types === "next") {
      console.log(types);
      if (pageNumber < data.pages) {
        pageNumber++;
      } else {
        console.log("error, accessing pahe number", pageNumber);
        return;
      }
    } else {
      /// Previous
      console.log(types);
      if (pageNumber > 1) {
        pageNumber--;
      } else {
        console.log("error, accessing pahe number", pageNumber);
        return;
      }
    }
    let body = {
      from: values.startDate,
      to: values.endDate,
      page: pageNumber,
    };
    getSenderIdDAta(body);
  };

  return (
    <Wrappr>
      <Container
        maxWidth="xl"
        disableGutters={isMobile ? true : false}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Box
          width="460px"
          sx={{ justifyContent: "center", textAlign: "center" }}
        >
          <Tabs
            className="view-type"
            value={view}
            onChange={(e, value) => setView(value)}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="SMS Templates"
              className={`view-type-name ${view === 0 ? "active" : ""}`}
              value={0}
            />
            <Tab
              label="Sender Id"
              className={`view-type-name ${view === 1 ? "active" : ""}`}
              value={1}
            />
            <Tab
              label="Entity Name"
              className={`view-type-name ${view === 2 ? "active" : ""}`}
              value={2}
            />
          </Tabs>
        </Box>

        {/* sms tamplate page */}
        {view === 0 && <SmsTemplateTab />}
        {/* entity name page */}
        {view === 2 && <EntityNameTab />}
        {/* sender id tab */}
        {view === 1 && (
          <Container maxWidth={"xl"}>
            <CustomLoader show={getAllSenderIdLoading} />

            {/* Dialog for changing the template status */}
            <ChangeTemplateStatus
              open={state.open}
              onClose={() => {
                setState((prev) => ({
                  ...prev,
                  open: false,
                }));
                getSenderIdDAta();
              }}
              handleRefresh={getSenderIdDAta}
              approvedApiUrl={approveSenderId}
              rejectedApiUrl={rejectSenderId}
              schoolDocId={state.legalEntityDocId}
            />
            <Box>
              <Typography className="filter" sx={{ py: 2 }}>
                Filter By:
              </Typography>

              <Stack direction="row" py="2">
                <Stack width="50%" direction="row" spacing={2}>
                  <Box>
                    <DesktopDatePicker
                      inputFormat="DD-MM-YYYY"
                      value={values.startDate}
                      name="startDate"
                      classsName="date"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, startDate: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select Date"
                          size="small"
                        />
                      )}
                      required
                    />
                  </Box>
                  <Box>
                    <DesktopDatePicker
                      inputFormat="DD-MM-YYYY"
                      value={values.endDate}
                      name="endDate"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, endDate: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select Date"
                          size="small"
                        />
                      )}
                      required
                    />
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{ height: "38.25px" }}
                    >
                      View
                    </Button>
                  </Box>
                </Stack>

                <Box
                  width="50%"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* <Button type='submit' variant="contained" color='secondary' sx={{ height: "34.25px" }}>View</Button> */}
                </Box>
              </Stack>
            </Box>
            <Box sx={{ mt: 2 }}>
              <CommonTable1
                columns={columns}
                data={data.list}
                enableBottomToolbar={false}
              />
              <Box className="table-footer">
                <Stack
                  direction={"row"}
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      height: "56px",
                      paddingLeft: "15px",
                    }}
                  >
                    <Typography className="show_entries">
                      Show entries:
                    </Typography>

                    <Select
                      id="demo-select-small"
                      //value={data.page ? data.page : 0}
                      onChange={(e) => {
                        PageChanger("page", e.target.value);
                      }}
                      size="small"
                      sx={{ marginLeft: "10px" }}
                      className="page-select"
                    >
                      {pages.map((pageNumber, index) => (
                        <MenuItem key={index} value={pageNumber}>
                          {pageNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      justifyContent: "end",
                      alignItems: "center",
                      paddingRight: "15px",
                    }}
                  >
                    <Typography className="show_entries">
                      {data.page} 0f {data.pages}
                    </Typography>
                    <Button
                      width="10px"
                      variant="outlined"
                      onClick={() => {
                        PageChanger("prev");
                      }}
                    >
                      <ChevronLeftIcon />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        PageChanger("next");
                      }}
                    >
                      <ChevronRightIcon />
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Container>
        )}
      </Container>
    </Wrappr>
  );
};

export default TextSMS;

const Wrappr = styled.div`
  .page-select {
    border-radius: 7px;
    border: 1px solid #b6c8d6;
    background: #fff;
  }
  .show_entries {
    color: #0b4977;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .table-footer {
    height: 56px;
    flex-shrink: 0;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #b6c8d6;
    background: #f6f8fc;
    z-index: -5;
    margin-top: -7px;
  }

  .filter {
    color: #0c2f49;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .view-type {
    border-radius: 10px;
    border: 1px solid rgba(19, 115, 229, 0.2);
    background: rgba(39, 137, 253, 0.05);
    height: 56px;
    flex-shrink: 0;
  }
  .view-type-name {
    color: #0c2f49;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
    text-transform: capitalize;
    margin: 0 10px;
  }
  .active {
    color: #0c2f49;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date {
    border-radius: 10px;
    border: 1px solid #b6c8d6;
    background: #fff;
  }

  .date-picker {
    width: 150px;
    flex-shrink: 0;
    border-radius: 10px;
    //border: 1px solid #B6C8D6;
    background: #fff;
  }
`;
