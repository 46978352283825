import { axiosInstance } from "../axios";

const orgdlt = `${process.env.REACT_APP_SMS_BASE}/orgdlt`;

export const getSMSaccountByOrg = (params) => {
  return axiosInstance.get(`${orgdlt}/getSMSaccountByOrg`, {
    params,
  });
};
export const getSmsTemplatesByOrg = (params) => {
  return axiosInstance.get(`${orgdlt}/getSmsTemplatesByOrg`, {
    params,
  });
};

export const addUpdateSMSaccount = (body) => {
  return axiosInstance.post(`${orgdlt}/addUpdateSMSaccount`, body);
};

export const getAllSenderId = (params) => {
  return axiosInstance.get(`${orgdlt}/getAllSenderId`, {
    params,
  });
};

export const getAllLegalEntity = (params) => {
  return axiosInstance.get(`${orgdlt}/getAllLegalEntity`, {
    params,
  });
};

export const approveSMStemplate = (body) => {
  return axiosInstance.put(`${orgdlt}/approveSMStemplate`, body);
};

export const rejectSMStemplate = (body) => {
  return axiosInstance.put(
    `${orgdlt}/rejectSMStemplate/${body?.tempalteDocId}`
  );
};

export const approveEntityName = (body) => {
  return axiosInstance.put(`${orgdlt}/approveEntityName/${body?.schoolDocId}`);
};

export const rejectEntityName = (body) => {
  return axiosInstance.put(`${orgdlt}/rejectEntityName/${body?.schoolDocId}`);
};

export const approveSenderId = (body) => {
  return axiosInstance.put(`${orgdlt}/approveSenderId/${body?.schoolDocId}`);
};

export const rejectSenderId = (body) => {
  return axiosInstance.put(`${orgdlt}/rejectSenderId/${body?.schoolDocId}`);
};
