import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { Distrct, States, boards } from "app/config/administrativeConstants";
import { MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const validationSchema = Yup.object({
  schoolInitials: Yup.string().required("School Initial required"),
  schoolName: Yup.string().required("School name required"),
  address: Yup.string().required("School address required"),
  dist: Yup.string().required("district required"),
  state: Yup.string().required("state required"),
  pinCode: Yup.string().required("Pin Code required"),
  affiliatedTo: Yup.string().required("affialted to  required"),
  educationType: Yup.string().required("education type required"),
  email: Yup.string().matches(emailRegex, "Invalid email"),
  mobileNumber: Yup.string().min(10, "10 digit required"),
});
const AddInstituteComponent = ({ formId = "" }) => {
  const [schoolMedium, setmedium] = React.useState([]);

  const handleClickMedium = (item) => {
    if (formik.values.medium?.includes(item)) {
      const filteredData = formik.values.medium.filter((res) => res !== item);
      formik.setValues((prev) => ({ ...prev, medium: filteredData }));
    } else {
      formik.setValues((prev) => ({ ...prev, medium: [...prev.medium, item] }));
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setmedium(typeof value === "string" ? value.split(",") : value);
  };
  const dt = new Date();
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      schoolLogoFile: "",
      coverPhotoFile: "",
      schoolLogo: "",
      coverPhoto: "",
      schoolInitials: "",
      schoolName: "",
      address: "",
      dist: "",
      pinCode: "",
      state: "",
      affiliatedTo: "",
      affiliationNumber: "",
      educationType: "",
      schoolRegisterNumber: "",
      schoolRegisterDate: "",
      schoolEmail: "",
      schoolWebsite: "",
      schoolContactNumber: "",
      schoolGstNumber: "",
      affiliationUpTo: "",
      medium: [],
      mediumList: ["Hindi", "English"],
      verifiedOn: "",
      establishmentDate: "",
      mobileNumber: "",
    },

    onSubmit: (values) => {
      const updateProfileFirstPayload = {
        schoolLogo: values.schoolLogo,
        schoolName: values.schoolName,
        schoolInitials: values.schoolInitials,
        schoolAddress: {
          address: values.address,
          dist: values.dist,
          pinCode: values.pinCode,
          state: values.state,
        },
        affiliatedTo: values.affiliatedTo,
        affiliationNumber: values.affiliationNumber,
        medium: schoolMedium,
        educationType: values.educationType,
        schoolRegisterNumber: values.schoolRegisterNumber,
        schoolRegisterDate: values.schoolRegisterDate,
        mobileNumber: String(values.mobileNumber),
        schoolContactNumber: String(values.schoolContactNumber),
        website: values?.schoolWebsite,
        email: values.schoolEmail,
      };

      // mutate(updateProfileFirstPayload);
    },
    enableReinitialize: true,
  });
  return (
    <>
      <UploadedCoverPhoto
        src={
          formik.values.coverPhoto
            ? formik.values.coverPhoto
            : "/banner-placeholder.png"
        }
      />
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        sx={{ borderRadius: "10px" }}
        component="label"
      >
        <input
          hidden
          type="file"
          accept="image/*"
          onChange={(e) =>
            formik.setValues((prev) => ({
              ...prev,
              coverPhoto: URL.createObjectURL(e.target.files[0]),
              coverPhotoFile: e.target.files[0],
            }))
          }
        />
        Upload Cover Photo
      </Button>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <ProfilePic
          src={
            formik.values.schoolLogo
              ? formik.values.schoolLogo
              : "/image-placeholder.jpeg"
          }
        />
        <Button
          component="label"
          variant="outlined"
          size="large"
          color="secondary"
          sx={{ borderRadius: "10px" }}
        >
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={(e) =>
              formik.setValues((prev) => ({
                ...prev,
                schoolLogo: URL.createObjectURL(e.target.files[0]),
                schoolLogoFile: e.target.files[0],
              }))
            }
          />
          Upload Logo
        </Button>
      </Stack>
      <form onSubmit={formik.handleSubmit} id={formId}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextFieldLabel title="School Initials" required />
            <CustomTextField
              size="small"
              fullWidth
              name="schoolInitials"
              placeholder="type school initial"
              value={formik.values.schoolInitials}
              onChange={formik.handleChange}
              error={
                formik.touched.schoolInitials &&
                Boolean(formik.errors.schoolInitials)
              }
              helperText={
                formik.touched.schoolInitials && formik.errors.schoolInitials
              }
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldLabel title="School Name" required />
            <CustomTextField
              id="schoolName"
              name="schoolName"
              size="small"
              placeholder="type school name"
              fullWidth
              value={formik.values.schoolName}
              onChange={formik.handleChange}
              error={
                formik.touched.schoolName && Boolean(formik.errors.schoolName)
              }
              helperText={formik.touched.schoolName && formik.errors.schoolName}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldLabel title="School Address" />
            <CustomTextField
              size="small"
              fullWidth
              id="address"
              name="address"
              placeholder="type school address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldLabel title="State" />
            <CustomSelectBox
              displayEmpty
              fullWidth
              size="small"
              id="state"
              value={formik.values.state}
              name="state"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {States.map((state, index) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldLabel title="District" />
            <CustomSelectBox
              size="small"
              fullWidth
              displayEmpty
              id="dist"
              value={formik.values.dist}
              name="dist"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
              error={formik.touched.dist && Boolean(formik.errors.dist)}
              helperText={formik.touched.dist && formik.errors.dist}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {formik.values.state &&
                Distrct[States.indexOf(String(formik.values.state)) + 1].map(
                  (district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  )
                )}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldLabel title="Pincode" />
            <CustomTextField
              fullWidth
              placeholder="type pin code"
              size="small"
              id="pinCode"
              name="pinCode"
              value={formik.values.pinCode}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 6,
              }}
              error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
              helperText={formik.touched.pinCode && formik.errors.pinCode}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldLabel title="Affiliated By" />
            <CustomSelectBox
              size="small"
              fullWidth
              displayEmpty
              id="affiliatedTo"
              value={formik.values.affiliatedTo}
              name="affiliatedTo"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
              error={
                formik.touched.affiliatedTo &&
                Boolean(formik.errors.affiliatedTo)
              }
              helperText={
                formik.touched.affiliatedTo && formik.errors.affiliatedTo
              }
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              <MenuItem value="none">None</MenuItem>
              {boards.map((board, idx) => (
                <MenuItem key={idx} value={board}>
                  {board}
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="Affiliation Upto" />
            <DatePicker
              inputFormat="DD-MM-YYYY"
              onChange={(e) =>
                formik.setValues((prev) => ({
                  ...prev,
                  affiliationUpTo: e,
                }))
              }
              value={formik.values.affiliationUpTo}
              renderInput={(params) => (
                <CustomTextField {...params} size="small" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="Affiliation No" />
            <CustomTextField
              size="small"
              fullWidth
              placeholder="Type affiliation Number"
              displayEmpt
              id="affiliationNumber"
              value={formik.values.affiliationNumber}
              name="affiliationNumber"
              onChange={formik.handleChange}
              error={
                formik.touched.affiliationNumber &&
                Boolean(formik.errors.affiliationNumber)
              }
              helperText={
                formik.touched.affiliationNumber &&
                formik.errors.affiliationNumber
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="Medium" />
            <CustomSelectBox
              displayEmpty
              fullWidth
              size="small"
              multiple
              value={schoolMedium}
              onChange={handleChange}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected?.length > 0) {
                  return (
                    <Typography>{formik.values?.medium?.join(", ")}</Typography>
                  );
                }
                return <Typography>Select</Typography>;
              }}
            >
              {formik.values?.mediumList?.map((medium) => (
                <MenuItem
                  key={medium}
                  value={medium}
                  onClick={() => handleClickMedium(medium)}
                >
                  <Checkbox checked={formik.values.medium.includes(medium)} />
                  <ListItemText primary={medium} />
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="Education Type" />
            <CustomSelectBox
              displayEmpty
              fullWidth
              size="small"
              id="educationType"
              value={formik.values.educationType}
              name="educationType"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              <MenuItem value={"Co-Ed"}>Co-Ed</MenuItem>
              <MenuItem value={"Only Boys"}>Only Boys</MenuItem>
              <MenuItem value={"Only Girls"}>Only Girls</MenuItem>
            </CustomSelectBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Registration Number" />
            <CustomTextField
              size="small"
              fullWidth
              placeholder="type school registration number"
              id="schoolRegisterNumber"
              name="schoolRegisterNumber"
              value={formik.values.schoolRegisterNumber}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Registration Date" />
            <DatePicker
              inputFormat="DD-MM-YYYY"
              onChange={(e) =>
                formik.setValues((prev) => ({
                  ...prev,
                  schoolRegisterDate: e,
                }))
              }
              value={formik.values.schoolRegisterDate}
              renderInput={(params) => (
                <CustomTextField {...params} size="small" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Email" />
            <CustomTextField
              size="small"
              fullWidth
              placeholder="type school email"
              name="schoolEmail"
              value={formik.values.schoolEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.schoolEmail && Boolean(formik.errors.schoolEmail)
              }
              helperText={
                formik.touched.schoolEmail && formik.errors.schoolEmail
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Website" />
            <CustomTextField
              placeholder="type school website"
              size="small"
              fullWidth
              id="schoolWebsite"
              name="schoolWebsite"
              value={formik.values.schoolWebsite}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Contact Number" />
            <CustomTextField
              size="small"
              placeholder="type school contact"
              fullWidth
              id="schoolContactNumber"
              name="schoolContactNumber"
              // type="number"
              value={formik.values.schoolContactNumber}
              // onChange={formik.handleChange}
              onChange={(e) => {
                if (String(e.target.value).length < 11) {
                  formik.handleChange(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School Mobile Number" />
            <CustomTextField
              placeholder="type school mobile Number"
              size="small"
              fullWidth
              id="mobileNumber"
              name="mobileNumber"
              type="number"
              error={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
              helperText={
                formik.touched.mobileNumber && formik.errors.mobileNumber
              }
              value={formik.values.mobileNumber}
              onChange={(e) => {
                if (String(e.target.value).length < 11) {
                  formik.handleChange(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="School GST Number" />
            <CustomTextField
              size="small"
              fullWidth
              placeholder="type school gst"
              id="schoolGstNumber"
              name="schoolGstNumber"
              value={formik.values.schoolGstNumber}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldLabel title="Establishment Date" />

            <DatePicker
              inputFormat="DD-MM-YYYY"
              onChange={(e) =>
                formik.setValues((prev) => ({
                  ...prev,
                  establishmentDate: e,
                }))
              }
              value={formik.values.establishmentDate}
              renderInput={(params) => (
                <CustomTextField {...params} size="small" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddInstituteComponent;
const UploadedCoverPhoto = styled("img")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 142px;
  border-radius: 20px;
  margin-bottom: 10px;
`;

const ProfilePic = styled("img")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  border-radius: 20px;
  margin: 10px;
`;
