import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AddInstituteDialog from "app/components/Dialogs/InstituteGroup/AddInstituteDialog";
import AddMoreStaffDialog from "app/components/Dialogs/InstituteGroup/AddMoreStaffDialog";
import UpdateInsituteProfile from "app/components/Dialogs/InstituteGroup/UpdateInsituteProfile";
import CustomLoader from "app/components/common/CustomLoader";
import appRoutes from "app/config/routes";
import { getInstituteGroup } from "app/services/org/school.service";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ViewInstituteGroup = () => {
  const [state, setState] = useState({
    obj: {},
    addStaffDialog: false,
    addInstituteDialog: false,
    updateInsituteGroupDialog: false,
  });
  const { id } = useParams();
  const {
    isLoading: getInstituteGroupLoading,
    isFetching: getInstituteGroupFetching,
    refetch: getInstituteGroupRefetching,
  } = useQuery({
    queryKey: ["getInstituteGroupById"],
    queryFn: () => getInstituteGroup({ instituteGroupDocId: id }),
    onSuccess: (success) => {
      setState((prev) => ({ ...prev, obj: success?.data?.obj }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: id ? true : false,
  });

  console.log("state", state.obj?.instituteGroupLogo);
  return (
    <>
      <CustomLoader
        show={getInstituteGroupLoading || getInstituteGroupFetching}
      />
      <AddMoreStaffDialog
        open={state.addStaffDialog}
        onClose={() => setState((prev) => ({ ...prev, addStaffDialog: false }))}
        refetch={getInstituteGroupRefetching}
        instituteArray={state.obj?.schoolList}
      />
      <UpdateInsituteProfile
        open={state.updateInsituteGroupDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, updateInsituteGroupDialog: false }))
        }
        refetch={getInstituteGroupRefetching}
        instituteGroupDocId={id}
        state={state?.obj}
      />
      <AddInstituteDialog
        open={state.addInstituteDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, addInstituteDialog: false }))
        }
        refetch={getInstituteGroupRefetching}
      />
      <Container maxWidth="md">
        <StyledWrapper>
          {/* top section */}
          <img
            alt="coverPhoto"
            src={
              state.obj?.coverPhoto
                ? state.obj?.coverPhoto
                : "/banner-placeholder.png"
            }
            className="cover_photo"
          />

          <Stack
            direction="row"
            justifyContent="center"
            sx={{ mt: "-40px", position: "relative" }}
          >
            <div className="logo_container">
              <img
                alt="logo"
                src={
                  state?.obj?.instituteGroupLogo
                    ? state?.obj?.instituteGroupLogo
                    : "/image-placeholder.jpeg"
                }
                className="logo_image"
              />
            </div>
            <div
              className="edit_button__wrapper"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  updateInsituteGroupDialog: true,
                }));
              }}
            >
              <Edit sx={{ color: "#fff" }} />
            </div>
          </Stack>
          <Box px={1.5} pb={1}>
            <Typography className="group_name">
              {state.obj?.instituteGroupName}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Label>Contact Number</Label>
                <Text>{state.obj?.contactNumber ?? "--"}</Text>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Website</Label>
                <Text>{state.obj?.website ?? "--"}</Text>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Email</Label>
                <Text>{state.obj?.email ?? "--"}</Text>
              </Grid>
            </Grid>
            <Typography className="text_title">Managing Directors</Typography>
            {state.obj?.staffList?.map((item, index) => (
              <CardWrapper key={index}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Label>Managing Director {index + 1}</Label>
                    <Text>
                      {item?.firstName ?? ""} {item?.middleName ?? ""}{" "}
                      {item?.lastName ?? ""}
                    </Text>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Label>Mobile Number</Label>
                    <Text>{item?.mobileNumber ?? "--"}</Text>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Label>Email</Label>
                    <Text>{item?.email ?? "--"}</Text>
                  </Grid>
                </Grid>
              </CardWrapper>
            ))}
            <Button
              onClick={() =>
                setState((prev) => ({ ...prev, addStaffDialog: true }))
              }
              variant="text"
              color="secondary"
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              + Add More
            </Button>
            <Typography className="text_title">Institute List</Typography>
            {state.obj?.schoolList?.map((item, index) => (
              <CardWrapper key={index}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={11}>
                    <Text>{item?.schoolName ?? ""}</Text>
                  </Grid>
                  <Grid item xs={1}>
                    <Link
                      to={`/${appRoutes.singleInstituteView}?schoolDocId=${item?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="viewImage"
                        src="/view-icon.png"
                        style={{
                          height: "25px",
                          width: "25px",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </CardWrapper>
            ))}
            <Button
              onClick={() =>
                setState((prev) => ({ ...prev, addInstituteDialog: true }))
              }
              variant="text"
              color="secondary"
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              + Add More
            </Button>
          </Box>
        </StyledWrapper>
      </Container>
    </>
  );
};

export default ViewInstituteGroup;
const StyledWrapper = styled(Box)`
  border-radius: 15px;
  border: 1px solid #b6c8d6;
  background: #fff;
  .cover_photo {
    height: 200px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
  }
  .logo_container {
    width: 82.17px;
    height: 82.17px;
    background: #f6f8fc;
    border: 1px solid rgba(182, 200, 214, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo_image {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 50%;
  }
  .edit_button__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28.09px;
    height: 28.09px;
    background: #2789fd;
    border: 2px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin-bottom: -10px;
    margin-left: 50px;
    cursor: pointer;
  }
  .group_name {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
  }
  .text_title {
    color: #0c2f49;
    font-size: 18px;
    font-weight: 600;
    margin-top: 8px;
  }
`;
const Label = styled(Typography)`
  color: #0c2f49;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
`;
const Text = styled(Typography)`
  color: #0c2f49;
  font-size: 16px;
  font-weight: 600;
`;
const CardWrapper = styled(Box)`
  border-radius: 8px;
  border: 1px solid #b6c8d6;
  background: #fff;
  padding: 10px;
  margin: 6px auto;
`;
