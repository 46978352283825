import { axiosInstance } from "../axios";
const product_route = `${process.env.REACT_APP_ORG_BASE}/product`;
export const getProduct = (params) => {
  return axiosInstance.get(`${product_route}/getProduct`, { params });
};
export const addProduct = (body) => {
  return axiosInstance.post(`${product_route}/addProduct`, body);
};
export const changeProductStatus = (body) => {
  return axiosInstance.put(`${product_route}/changeProductStatus`, body);
};
export const deleteProduct = (id) => {
  return axiosInstance.delete(`${product_route}/deleteProduct/${id}`);
};
export const getAllSessionOrder = (params) => {
  return axiosInstance.get(`${product_route}/getAllSessionOrder`, { params });
};
export const addSessionOrder = (body) => {
  return axiosInstance.post(`${product_route}/addSessionOrder`, body);
};

export const getTransactionBySessionOrder = (params) => {
  return axiosInstance.get(`${product_route}/getTransactionBySessionOrder`, {
    params,
  });
};

export const deleteSessionOrder = (id) => {
  return axiosInstance.delete(`${product_route}/deleteSessionOrder/${id}`);
};

export const changeDueDateOfSessionOrder = (body) => {
  return axiosInstance.put(
    `${product_route}/changeDueDateOfSessionOrder`,
    body
  );
};
