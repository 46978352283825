import { createSlice } from "@reduxjs/toolkit";

export const CommonSlice = createSlice({
  name: "auth",
  initialState: {
    selectedSession: "2024-2025",
  },
  reducers: {
    setSession: (state, { payload }) => {
      state.selectedSession = payload;
    },
  },
});
export default CommonSlice.reducer;
export const { setSession } = CommonSlice.actions;
