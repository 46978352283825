import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import ChangeTemplateStatus from "app/components/Dialogs/Communication/ChangeTemplateStatus";
import CommonTable1 from "app/components/Tables/CommonTable1";
import {
  approveEntityName,
  getAllLegalEntity,
  rejectEntityName,
} from "app/services/sms/orgdlt.service";

import moment from "moment";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const EntityNameTab = () => {
  const [state, setState] = useState({
    open: false,
    legalEntityDocId: "",
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Entity Name",
        accessorKey: "legalEntityName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.legalEntityName ?? "--"}</Typography>;
        },
      },
      {
        header: "Approved Entity ID",
        accessorKey: "approvedEntityId",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.approvedEntityId ?? "--"}</Typography>;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography
              sx={{
                color:
                  original?.status === "APPROVED"
                    ? "green"
                    : original?.status === "REJECTED"
                    ? "red"
                    : "orange",
              }}
            >
              {original?.status ?? "--"}
            </Typography>
          );
        },
      },
      {
        header: "Created By",
        accessorKey: "createdBy.personName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>{original?.createdBy?.personName ?? "--"}</Typography>
          );
        },
      },
      {
        header: "Created On",
        accessorKey: "createdOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {original?.createdOn
                ? moment(original?.createdOn).format("DD-MM-YYYY")
                : "--"}
            </Typography>
          );
        },
      },
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ cell }) => {
          if (
            cell?.row?.original?.status === "APPROVED" ||
            cell?.row?.original?.status === "REJECTED"
          ) {
            return null;
          } else {
            return (
              <Box>
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        open: true,
                        legalEntityDocId: cell?.row?.original?._id,
                      }));
                    }}
                  >
                    Approve
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        open: true,
                        legalEntityDocId: cell?.row?.original?._id,
                      }));
                    }}
                  >
                    Reject
                  </LoadingButton>
                </Stack>
              </Box>
            );
          }
        },
      },
    ];
  }, []);

  const {
    isLoading: getAllLegalEntityLoading,
    isFetching: getAllLegalEntityFetching,
    refetch: getAllLegalEntityRefetch,
    data: getAllLegalEntityData,
  } = useQuery({
    queryKey: ["getAllLegalEntity"],
    queryFn: () => getAllLegalEntity({}),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CustomLoader
        show={getAllLegalEntityLoading || getAllLegalEntityFetching}
      />

      <ChangeTemplateStatus
        open={state.open}
        onClose={() => {
          setState((prev) => ({
            ...prev,
            open: false,
          }));
          getAllLegalEntityRefetch();
        }}
        handleRefresh={getAllLegalEntityRefetch}
        approvedApiUrl={approveEntityName}
        rejectedApiUrl={rejectEntityName}
        schoolDocId={state.legalEntityDocId}
      />

      <Box sx={{ mt: 2 }}>
        <CommonTable1
          columns={columns}
          data={getAllLegalEntityData?.data?.list ?? []}
          maxHeight="70vh"
        />
      </Box>
    </>
  );
};

export default EntityNameTab;
