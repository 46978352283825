import { axiosInstance } from "../axios";
const school_route = `${process.env.REACT_APP_ORG_BASE}/school`;
export const updateStudentLimit = (body) => {
  return axiosInstance.put(`${school_route}/updateStudentLimit`, body);
};
export const getInstituteGroup = (params) => {
  return axiosInstance.get(`${school_route}/getInstituteGroup`, { params });
};
export const getStaffListByInstitute = (params) => {
  return axiosInstance.get(`${school_route}/getStaffListByInstitute`, {
    params,
  });
};
export const getSchool = (params) => {
  return axiosInstance.get(`${school_route}/getSchool`, {
    params,
  });
};
export const getSchoolByName = (params) => {
  return axiosInstance.get(`${school_route}/getSchoolByName`, {
    params,
  });
};
export const addStaffInInstituteGroup = (body) => {
  return axiosInstance.put(`${school_route}/addStaffInInstituteGroup`, body);
};
export const updateInstituteGroup = (body) => {
  return axiosInstance.put(`${school_route}/updateInstituteGroup`, body);
};
export const linkSchoolWithInstituteGroup = (body) => {
  return axiosInstance.put(
    `${school_route}/linkSchoolWithInstituteGroup`,
    body
  );
};
export const addInstituteGroup = (body) => {
  return axiosInstance.post(`${school_route}/addInstituteGroup`, body);
};
export const uploadLicense = (body) => {
  return axiosInstance.put(`${school_route}/uploadLicense`, body);
};
